/* eslint-disable react-hooks/exhaustive-deps  */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import moment from 'moment';
import Carousel from '../../components/application/Carousel';
import PromoCard from '../../components/application/PromoCard';
import SliderCard from '../../components/application/SliderCard';
import Loading from '../../components/crosscutting/Loading';

import { getPackagesAction } from '../../actions/home.action';
import { getPurchaseOrderParametersAction } from '../../actions/general.action';
import {
  responsiveBase,
  responsiveCombo,
  responsiveComplementary,
  responsiveBaseWithoutOffer,
  responsiveComplementaryWithoutCombo,
} from '../../helpers/confResponsiveSlider';

const breakpoints = createBreakpoints({});

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
  },
  gridItem: {
    minHeight: 600,
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    backgroundColor: '#4B4B4B',
    borderRadius: '10px',
  },
  paperPaquetes: {
    ...theme.home.paquetes,
    '& .slick-list': {
      [breakpoints.up('xs')]: {
        paddingLeft: '5px',
      },
      [breakpoints.up('sm')]: {
        paddingLeft: '30px',
      },
      [breakpoints.up('md')]: {
        paddingLeft: '30px',
      },
      [breakpoints.up('lg')]: {
        paddingLeft: '10px',
      },
      [breakpoints.up('xl')]: {
        paddingLeft: '10px',
      },
    },
  },
  paperBaseWithoutOffers: {
    ...theme.home.paquetes,
    '& .slick-list': {
      [breakpoints.up('xs')]: {
        paddingLeft: '5px',
      },
      [breakpoints.up('sm')]: {
        paddingLeft: '30px',
      },
      [breakpoints.up('md')]: {
        paddingLeft: '40px',
      },
      [breakpoints.up('lg')]: {
        paddingLeft: '35px',
      },
      [breakpoints.up('xl')]: {
        paddingLeft: '35px',
      },
    },
  },
  paperCombos: {
    ...theme.home.combos,
    '& .slick-list': {
      [breakpoints.up('xs')]: {
        paddingLeft: '5px',
      },
      [breakpoints.up('sm')]: {
        paddingLeft: '150px',
      },
      [breakpoints.up('md')]: {
        paddingLeft: '30px',
      },
      [breakpoints.up('lg')]: {
        paddingLeft: '70px',
      },
      [breakpoints.up('xl')]: {
        paddingLeft: '20px',
      },
    },
  },
  paperAgregados: {
    ...theme.home.agregados,
    '& .slick-list': {
      [breakpoints.up('xs')]: {
        paddingLeft: '5px',
      },
      [breakpoints.up('sm')]: {
        paddingLeft: '150px',
      },
      [breakpoints.up('md')]: {
        paddingLeft: '30px',
      },
      [breakpoints.up('lg')]: {
        paddingLeft: '70px',
      },
      [breakpoints.up('xl')]: {
        paddingLeft: '20px',
      },
    },
  },
}));

const Home = (props) => {
  const { packageId } = useParams();
  const {
    packages, loading, history, appName, getPackage, getPurchaseOrderParameters, applicationInfo,
  } = props;
  const [showLoading, setShowLoading] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    offers, base, combo, complementary,
  } = packages;
  // const paletteHeader = ['blue', 'green', 'yellow'];
  const paletteHeader = theme.home.bgColorHead.bases.split(',');
  const arraypaletteHeaderComp = theme.home.bgColorHead.arrayComplementary.split(',');
  const paletteHeaderComp = theme.home.bgColorHead.complement;

  useEffect(() => {
    getPurchaseOrderParameters();
  }, []);

  useEffect(() => {
    if (appName === 'colnomina' || appName === 'firmaya' || appName === 'superguarda' || appName === 'bemyself') {
      if (applicationInfo?.urlLandingPage) {
        window.location.href = applicationInfo?.urlLandingPage;
      }
    } else {
      history.push('/');
    }
  }, [applicationInfo]);

  useEffect(() => {
    if (appName === 'colnomina' || appName === 'firmaya' || appName === 'superguarda' || appName === 'bemyself') {
      setShowLoading(true);
    }
  }, [appName]);

  useEffect(() => {
    getPackage(appName, packageId);
    localStorage.setItem('redirectPage', 'home');
  }, [getPackage]);

  const validateDate = (offer) => {
    const offerExpirationDate = new Date(offer.offerExpirationTime);
    const expirationDateMoment = moment(offerExpirationDate);
    const currentDate = new Date();
    if (expirationDateMoment.diff(currentDate, 'days') > 90) {
      offerExpirationDate.setFullYear(currentDate.getFullYear());
      // First period January - March
      if (currentDate.getMonth() >= 0 && currentDate.getMonth() <= 2) {
        offerExpirationDate.setMonth(3);
        offerExpirationDate.setMonth(3);
        offerExpirationDate.setDate(1);
        offerExpirationDate.setHours(0);
        offerExpirationDate.setMinutes(0);
        offerExpirationDate.setSeconds(0);
      } else if (currentDate.getMonth() >= 3 && currentDate.getMonth() <= 5) {
        // Second period April - June
        offerExpirationDate.setMonth(6);
        offerExpirationDate.setMonth(6);
        offerExpirationDate.setDate(1);
        offerExpirationDate.setHours(0);
        offerExpirationDate.setMinutes(0);
        offerExpirationDate.setSeconds(0);
      } else if (currentDate.getMonth() >= 6 && currentDate.getMonth() <= 8) {
        // Third period July - September
        offerExpirationDate.setMonth(9);
        offerExpirationDate.setMonth(9);
        offerExpirationDate.setDate(1);
        offerExpirationDate.setHours(0);
        offerExpirationDate.setMinutes(0);
        offerExpirationDate.setSeconds(0);
      } else if (currentDate.getMonth() >= 9 && currentDate.getMonth() <= 11) {
        // Fourth period October - December
        offerExpirationDate.setMonth(11);
        offerExpirationDate.setMonth(11);
        offerExpirationDate.setDate(31);
        offerExpirationDate.setHours(23);
        offerExpirationDate.setMinutes(59);
        offerExpirationDate.setSeconds(59);
      }
    }
    // eslint-disable-next-line no-param-reassign
    offer.offerExpirationTime = offerExpirationDate;
    return offer;
  };

  if (loading || showLoading) return <Loading />;

  return (
    <>
      <Grid container style={{ overflowX: 'hidden', padding: isXs ? '10px 0 0' : 20 }}>
        <Grid item xs={false} sm={1} />
        <Grid item xs={12} sm={10}>
          <Grid container spacing={3} style={{ overflowX: 'hidden' }}>
            {offers?.length > 0 && (
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <Carousel
                  autoplay
                  arrows={false}
                >
                  { offers && offers.map((item) => (
                    <PromoCard
                      card={validateDate(item)}
                      key={item.packageId}
                      isPublic={item.publicVisibility}
                    />
                  ))}
                </Carousel>
              </Grid>
            )}
            <Grid item xs={12} md={offers?.length > 0 ? 8 : 12} className={classes.gridItem}>
              <Paper elevation={5} className={`${classes.paper} ${offers?.length > 0 ? classes.paperPaquetes : classes.paperBaseWithoutOffers}`}>
                <Carousel slidesToShow={offers?.length > 0 ? 3 : 4} responsive={offers?.length > 0 ? responsiveBase : responsiveBaseWithoutOffer}>
                  { base && base.map((item, index) => {
                    return (
                      <SliderCard
                        key={item.packageId}
                        card={item}
                        colorBgHeader={paletteHeader[index]}
                        isPublic={item.publicVisibility}
                        textColor="#FFF"
                      />
                    );
                  })}
                </Carousel>
              </Paper>
            </Grid>
            {combo?.length > 0 && (
            <Grid item xs={12} md={6} className={classes.gridItem}>
              <Paper elevation={5} className={`${classes.paper} ${classes.paperCombos}`}>
                <Carousel slidesToShow={2} responsive={responsiveCombo}>
                  { combo && combo.map((item) => (
                    <SliderCard
                      key={item.packageId}
                      card={item}
                      isPublic={item.publicVisibility}
                      colorBgHeader={theme.home.bgColorHead.combos}
                      textColor={theme.home.sliderComboText.color}
                      colorBgSlider={theme.home.sliderBackGroundColor.color}
                    />
                  ))}
                </Carousel>
              </Paper>
            </Grid>
            )}
            <Grid item xs={12} md={combo?.length > 0 ? 6 : 12} className={classes.gridItem}>
              <Paper elevation={5} className={`${classes.paper} ${classes.paperAgregados}`}>
                <Carousel
                  rows={2}
                  slidesToShow={3}
                  responsive={combo?.length > 0 ? responsiveComplementary : responsiveComplementaryWithoutCombo}
                >
                  { complementary && complementary.map((item, index) => {
                    return (
                      <SliderCard
                        key={item.packageId}
                        card={item}
                        showList={false}
                        isPublic={item.publicVisibility}
                        colorBgHeader={`${paletteHeaderComp}${arraypaletteHeaderComp[index]}`}
                        textColor={theme.home.sliderComplementText.color}
                        buttonColor={theme.home.sliderComplementaryButtonColor.color}
                        buttonTextColor={theme.home.sliderComplementaryButtonTextColor.color}
                      />
                    );
                  })}
                </Carousel>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={false} sm={1} />
      </Grid>
    </>
  );
};

const mapStateToProps = (props) => ({
  loading: props.home.loading,
  packages: props.home.packages,
  appName: props.general.appName,
  applicationInfo: props.general.applicationInfo,
});

const mapDispatchToProps = {
  getPackage: getPackagesAction,
  getPurchaseOrderParameters: getPurchaseOrderParametersAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
